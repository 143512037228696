<template>
  <div id="payable-detail">
    <el-dialog title=" 【费用修改记录】" :close-on-click-modal="false" width="70%" :visible.sync="visible" :modal-append-to-body='false'>
      <div class="tableBox">
        <el-table height="55vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%;">
           <el-table-column prop="simpleName" label="车队名称" :show-overflow-tooltip="true" min-width="90">
          </el-table-column>
          <el-table-column prop="businessName" label="车牌号" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="orderNum" label="订单数" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="auditNum" label="已出账单订单数" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="totalMoney" label="应收金额" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="payMoney" label="已收金额" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="notPayMoney" label="未收金额" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="invoice" label="已开票金额">
          </el-table-column>
          <el-table-column prop="notInvoice" label="未开票金额">
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间" min-width="120">
          </el-table-column>
          <el-table-column prop="createName" label="操作人">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      rowMap: {},
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false
    }
  },
  components: {
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    init (row, type) {
      this.visible = true
      this.rowMap = {
        row: row,
        type: type
      }
      if(row && row.id) {
        this.getDataList()
      }
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(`/costSummary/findList/2`),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'businessId': this.rowMap.row.id
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
      this.dataList = [{}]
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
#payable-detail {
  .el-dialog {
    top: 50%;
    margin-top: 0 !important;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
